(function () {

    var form = $('#login-form');
    var pinModal = $('#pin-modal');

    var authData = null;

    var showPinModal = function () {
        pinModal.modal('show');
    };

    var routeToReturnUrl = function () {
        window.location.href = $('#return-url').val();
    };

    var lockPinModal = function (state) {
        //if (state) {
        //    pinModal.off('keyup.dismiss.bs.modal'); // disable escape key
        //    pinModal.data('bs.modal').options.backdrop = 'static';
        //}
        //else {
        //    pinModal.data('bs.modal').escape(); // reset keyboard
        //    pinModal.data('bs.modal').options.backdrop = true;
        //}
        //pinModal.find('input').prop('disabled', state);
    };

    var lockForm = function (state) {
        form.toggleClass('locked', state);
        form.find('input, button').prop('disabled', state);
    };

    var validatePin = function () {

        var pin = $.trim(pinModal.find('input:text').val());

        if (pin === ''){
            alert('You must enter a PIN');
            return;
        }

        lockPinModal(true);

        $.post('/login/validate-pin', {
            loginId: authData.guid,
            pin: pin,
            __RequestVerificationToken: $('input[name=__RequestVerificationToken]').val()
        }, function (data) {
            if (data.status === 500) {
                alert("An unknown error has occured");
                lockPinModal(false);
            }
            else if(data.status === 403){
                alert('Invalid PIN');
                lockPinModal(false);
            }
            else if (data.status === 404) {
                alert('This PIN is no longer valid (probably by a newer attempted login)');
                lockPinModal(false);
            }
            else {
                routeToReturnUrl();
            }
        }, 'JSON');
    };

    var addEvents = function () {
        pinModal.on('shown.bs.modal', function (e) {
            pinModal.find('input:text').focus();
        });
        pinModal.on('hidden.bs.modal', function (e) {
            pinModal.find('input:text').val('');
            lockForm(false);
        });

        pinModal.keypress(function (e) {
            if (e.which === 13) {
                validatePin();
            }
        });

        pinModal.find('input:button').click(function (e) {
            e.preventDefault();
            validatePin();
        });

        form.find('button').click(function (e) {
            e.preventDefault();
            lockForm(true);
            $.post('/login/authenticate', {
                Email: $('#Email').val(),
                Password: $('#Password').val(),
                __RequestVerificationToken: $('input[name=__RequestVerificationToken]').val()
            }, function (data) {
                if (data.status < 200 || data.status > 299) {
                    alert(data.errors.join('\n'));
                    lockForm(false);
                }
                else {
                    authData = data;
                    if (data.status === 201) {
                        showPinModal();
                    }
                    else if (data.status === 200) {
                        routeToReturnUrl();
                    }
                }
            }, 'JSON');
        });
    };

    var init = function () {
        form.find('button').prop('disabled', false);
        addEvents();
    };

    init();

}());